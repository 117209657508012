.hero-containt-main .hero-subtitle-text {
  font-size: 40px;
  line-height: 48px;
  font-family: "Super-Senior";
  color: var(--main-white);
  text-align: center;
}

.hero-containt-main .hero-titles {
  font-size: 140px;
  line-height: 141px;
  font-family: "Doggies-Silhouette-Font-Regular";
  text-align: center;
  letter-spacing: -10px;
  word-spacing: -21px;
  position: relative;
  max-width: max-content;
  margin: auto;
}

.hero-containt-main .hero-animation-title {
  -webkit-animation: iconpulse 1s ease-in-out infinite alternate;
  animation: iconpulse 1s ease-in-out infinite alternate;
}

@keyframes iconpulse {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
}

.hero-dog-img {
  width: 557px;
  height: 605px;
  object-fit: contain;
}

.hero-section {
  background-image: url(../../../../../assets/images/svg/hero-bg.svg);
  width: 100%;
  /* height: 100vh;
      min-height: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 45px;
  /* margin-top: -38px; */
  position: relative;
}

.hero-main-img-box {
  display: flex;
  justify-content: center;
  /* max-width: max-content; */
  position: relative;
  margin: -85px auto auto auto;
}

.hero-containt-main {
  padding-top: 30px;
}

.main-white {
  color: var(--main-white);
}

.main-green {
  color: var(--green);
}

.main-pink {
  color: var(--pink);
}

.hero-scroll-down-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -21px;
  z-index: 999;
  position: relative;
}

.hero-scroll-down-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: -60px; */
}

.scroll-down-img-box {
  object-fit: contain;
}

.scroll-down-img-box svg{
  height: 60px;
  width: 60px;
}

.hero-scroll-down-main .scrolldown-text {
  font-size: 25px;
  line-height: 32px;
  font-family: "Super-Senior";
  color: var(--main-white);
  text-align: center;
  margin-top: 9px;
}

.hero-wif-text {
  font-family: "Super-Senior";
  color: var(--main-white);
  position: absolute;
}

.hero-section::after {
  position: absolute;
  content: "";
  background-image: radial-gradient(#00eb94, #00eb94);
  filter: blur(280px);
  height: 330px;
  width: 330px;
  top: 28%;
  left: 4%;
  z-index: -1;
}

.hero-section::before {
  position: absolute;
  content: "";
  background-image: radial-gradient(#cf34f9, #cf34f9);
  filter: blur(356px);
  height: 330px;
  width: 330px;
  top: -18%;
  right: 500px;
  z-index: -1;
}

.hero-main-img-box::after {
  content: "";
  position: absolute;
  background-image: url(../../../../../assets/images/png/dog-bg.png);
  height: 195px;
  width: 100%;
  background-repeat: no-repeat;
  filter: blur(31px);
  left: 0px;
  right: 0px;
  bottom: -38px;
  margin: auto;
  background-position: center;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

  40% {
    transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
}

  60% {
    transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
}
}

.zoom-in-zoom-out {
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
}

  50% {
    transform: scale(1.5, 1.5);
    -webkit-transform: scale(1.5, 1.5);
    -moz-transform: scale(1.5, 1.5);
    -ms-transform: scale(1.5, 1.5);
    -o-transform: scale(1.5, 1.5);
}

  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
}
}

/* / ===================== hero section animation =============================== / */
.hero-wif-text-left {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.hero-wif-text-right {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.hero-wif-1 {
  animation: hero-wif-1 5s 0s both;
  -webkit-animation: hero-wif-1 5s 0s both;
  opacity: 0.4;
  font-size: 100px;
  line-height: 110px;
  top: 65%;
  left: 8%;
}

.hero-wif-2 {
  animation: hero-wif-2 5s 2s both;
  -webkit-animation: hero-wif-2 5s 2s both;
  opacity: 0.26;
  font-size: 85px;
  line-height: 90px;
  top: 79%;
  left: 20%;
}

.hero-wif-3 {
  animation: hero-wif-3 5s 4s both;
  -webkit-animation: hero-wif-3 5s 4s both;
  opacity: 0.4;
  font-size: 70px;
  line-height: 70px;
  top: 49%;
  left: 25%;
}

.hero-wif-4 {
  animation: hero-wif-4 5s 6s both;
  -webkit-animation: hero-wif-4 5s 6s both;
  opacity: 0.26;
  font-size: 55px;
  line-height: 49px;
  top: 87%;
  left: 31%;
}

.hero-wif-5 {
  animation: hero-wif-5 5s 8s both;
  -webkit-animation: hero-wif-5 5s 8s both;
  opacity: 0.41;
  font-size: 40px;
  line-height: 39px;
  top: 60%;
  left: 34%;
}

.hero-wif-6 {
  animation: hero-wif-6 5s 10s both;
  -webkit-animation: hero-wif-6 5s 10s both;
  opacity: 1;
  font-size: 30px;
  line-height: 35px;
  top: 72%;
  left: 42%;
}

.hero-wif-7 {
  animation: hero-wif-7 5s 1s both;
  -webkit-animation: hero-wif-7 5s 1s both;
  opacity: 0.4;
  font-size: 100px;
  line-height: 110px;
  top: 50%;
  right: 8%;
}

.hero-wif-8 {
  animation: hero-wif-8 5s 3s both;
  -webkit-animation: hero-wif-8 5s 3s both;
  opacity: 0.4;
  font-size: 85px;
  line-height: 90px;
  top: 70%;
  right: 17%;
}

.hero-wif-9 {
  animation: hero-wif-9 5s 5s both;
  -webkit-animation: hero-wif-9 5s 5s both;
  opacity: 0.4;
  font-size: 70px;
  line-height: 70px;
  top: 93%;
  right: 3%;
}

.hero-wif-10 {
  animation: hero-wif-10 5s 7s both;
  -webkit-animation: hero-wif-10 5s 7s both;
  opacity: 1;
  font-size: 55px;
  line-height: 49px;
  top: 90%;
  right: 26%;
}

.hero-wif-11 {
  animation: hero-wif-11 5s 9s both;
  -webkit-animation: hero-wif-11 5s 9s both;
  opacity: 0.41;
  font-size: 40px;
  line-height: 39px;
  top: 60%;
  right: 31%;
}

.hero-wif-12 {
  animation: hero-wif-12 5s 11s both;
  -webkit-animation: hero-wif-12 5s 11s both;
  opacity: 1;
  font-size: 30px;
  line-height: 29px;
  top: 70%;
  right: 38%;
}

@keyframes hero-wif-1 {
  0% {
    top: 70%;
    left: 50%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 0.3;
  }

  100% {
    top: 65%;
    left: 8%;
    opacity: 0.4;
  }
}

@keyframes hero-wif-2 {
  0% {
    top: 70%;
    left: 50%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.15;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    top: 79%;
    left: 20%;
    opacity: 0.26;
  }
}

@keyframes hero-wif-3 {
  0% {
    top: 70%;
    left: 50%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 0.3;
  }

  100% {
    top: 49%;
    left: 25%;
    opacity: 0.4;
  }
}

@keyframes hero-wif-4 {
  0% {
    top: 70%;
    left: 50%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.15;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    top: 87%;
    left: 31%;
    opacity: 0.26;
  }
}

@keyframes hero-wif-5 {
  0% {
    top: 70%;
    left: 50%;
    opacity: 0;
  }

  25% {
    opacity: 0.11;
  }

  50% {
    opacity: 0.21;
  }

  75% {
    opacity: 0.31;
  }

  100% {
    top: 60%;
    left: 34%;
    opacity: 0.41;
  }
}

@keyframes hero-wif-6 {
  0% {
    top: 70%;
    left: 50%;
    opacity: 0;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.4;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    top: 72%;
    left: 42%;
    opacity: 1;
  }
}

@keyframes hero-wif-7 {
  0% {
    top: 70%;
    right: 38%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 0.3;
  }

  100% {
    top: 50%;
    right: 8%;
    opacity: 0.4;
  }
}

@keyframes hero-wif-8 {
  0% {
    top: 70%;
    right: 38%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 0.3;
  }

  100% {
    top: 70%;
    right: 17%;
    opacity: 0.4;
  }
}

@keyframes hero-wif-9 {
  0% {
    top: 70%;
    right: 42%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  75% {
    opacity: 0.3;
  }

  100% {
    top: 93%;
    right: 3%;
    opacity: 0.4;
  }
}

@keyframes hero-wif-10 {
  0% {
    top: 70%;
    right: 42%;
    opacity: 0;
  }

  25% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.15;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    top: 90%;
    right: 26%;
    opacity: 0.26;
  }
}

@keyframes hero-wif-11 {
  0% {
    top: 70%;
    right: 43%;
    opacity: 0;
  }

  25% {
    opacity: 0.11;
  }

  50% {
    opacity: 0.21;
  }

  75% {
    opacity: 0.31;
  }

  100% {
    top: 60%;
    right: 31%;
    opacity: 0.41;
  }
}

@keyframes hero-wif-12 {
  0% {
    top: 70%;
    right: 43%;
    opacity: 0;
  }

  25% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.4;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    top: 70%;
    right: 38%;
    opacity: 1;
  }
}

/* / ===================== hero section animation =============================== / */

/* .scroll-down-img-box:hover {
      background: green;
      transform: scale(1.2);
      border: 1px solid yellow;
} */

/* .scroll-down-img-box:hover svg path {
  fill: var(--green);
  stroke-width: 1px;
  stroke: var(--dark-blue);
} */

/* @keyframes moveAnimation {
  0% {
    top: 0px;
  }
  50% {
    transform: rotate(3deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  100% {
    top: 0px;
  }
}

.animate-move {
  animation: moveAnimation 0.5s ease infinite;
} */

.dog-hover-left {
  transform: perspective(1500px) rotateY(25deg);
  transition: transform 3s ease 0s;
  -webkit-transition: transform 3s ease 0s;
  -moz-transition: transform 3s ease 0s;
  -ms-transition: transform 3s ease 0s;
  -o-transition: transform 3s ease 0s;
  -webkit-transform: perspective(1500px) rotateY(25deg);
  -moz-transform: perspective(1500px) rotateY(25deg);
  -ms-transform: perspective(1500px) rotateY(25deg);
  -o-transform: perspective(1500px) rotateY(25deg);
}

.dog-hover-right {
  transform: perspective(1500px) rotateY(-25deg);
  transition: transform 3s ease 0s;
  -webkit-transform: perspective(1500px) rotateY(-25deg);
  -moz-transform: perspective(1500px) rotateY(-25deg);
  -ms-transform: perspective(1500px) rotateY(-25deg);
  -o-transform: perspective(1500px) rotateY(-25deg);
  -webkit-transition: transform 3s ease 0s;
  -moz-transition: transform 3s ease 0s;
  -ms-transition: transform 3s ease 0s;
  -o-transition: transform 3s ease 0s;
}

.dog-hover-center {
      transform: none;
      transition: transform 3s ease 0s;
      -webkit-transition: transform 3s ease 0s;
      -moz-transition: transform 3s ease 0s;
      -ms-transition: transform 3s ease 0s;
      -o-transition: transform 3s ease 0s;
}

.scroll-down-animation path{
  animation: changeColor 3s ease-in-out infinite ;
  -webkit-animation: changeColor 3s ease-in-out infinite ;
}

@keyframes changeColor {
  0% {
    fill: #ffffff; /* Initial color */
  }
  50% {
    fill: #00f08f; /* Intermediate color */
  }
  100% {
    fill: #d525fa; /* Final color */
  }
}