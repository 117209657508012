@media screen and (min-width: 1921px) and (max-width: 2500px) {
  @keyframes moveclouds {
    0% {
      transform: translateX(-200px) rotate(360deg);
      -webkit-transform: translateX(-200px) rotate(360deg);
    }

    100% {
      transform: translateX(630px) rotate(19deg);
      -webkit-transform: translateX(630px) rotate(19deg);
    }
  }

  .follow-logo-bottm {
    bottom: -38px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1920px) {
  @keyframes moveclouds {
    0% {
      transform: translateX(-200px) rotate(360deg);
      -webkit-transform: translateX(-200px) rotate(360deg);
    }

    100% {
      transform: translateX(500px) rotate(19deg);
      -webkit-transform: translateX(500px) rotate(19deg);
    }
  }

  .follow-logo-bottm {
    bottom: -38px;
  }

  .about-para-main {
    margin-left: 0px;
  }

  .token-bone-animation svg {
    width: 700px;
    height: 270px;
  }

  .follow-left-img-main .follow-lamp-details {
    max-width: 730px;
  }

  .lamp-two {
    left: 160px;
  }

  .lamp-two svg,
  .lamp-four svg {
    height: 85px;
  }

  .lamp-four {
    left: 162px;
  }

  .follow-containt-main .follow-text {
    font-size: 62px;
    line-height: 70px;
  }

  .follow-containt-main {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1348px) and (max-width: 1599px) {
  @keyframes moveclouds {
    0% {
      transform: translateX(-200px) rotate(360deg);
      -webkit-transform: translateX(-200px) rotate(360deg);
    }

    100% {
      transform: translateX(430px) rotate(19deg);
      -webkit-transform: translateX(430px) rotate(19deg);
    }
  }

  .footer-social-icons {
    height: 55px;
    width: 55px;
  }

  .header-nav-main .nav-ul {
    gap: 70px;
  }

  .border-btn-main .border-btn {
    font-size: 19px;
    line-height: 21px;
    padding: 16px 33px;
  }

  .header-btn-flex {
    gap: 25px;
  }

  .container {
    max-width: 1350px;
  }

  .hero-containt-main .hero-subtitle-text {
    font-size: 38px;
    line-height: 46px;
  }

  /* follow css */

  .follow-lamp-details {
    max-width: 597px;
    display: flex;
  }

  .follow-left-img {
    height: 292px;
    object-position: bottom;
  }

  .lamp-one {
    bottom: -5px;
    left: 6px;
  }

  .lamp-two {
    bottom: -5px;
    left: 131px;
  }

  .lamp-two svg,
  .lamp-four svg {
    height: 70px;
  }

  .follow-containt-main {
    margin-top: 40px;
  }

  .follow-containt-main .follow-text {
    font-size: 50px;
    line-height: 80px;
    max-width: 370px;
  }

  .follow-social-main {
    margin-top: 30px;
  }

  .follow-social-main .follow-social-icons {
    height: 55px;
    width: 55px;
  }

  .follow-right-img-main .follow-right-img {
    height: 292px;
  }

  .lamp-four {
    left: 161px;
  }

  /* .responsive-logo-follow{
    height: 150px;
  } */
  /* follow css */

  .footer-main {
    margin-top: 170px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .container {
    max-width: 1280px;
  }

  .header-nav-main .nav-link {
    font-size: 16px;
    line-height: 20px;
  }

  .border-btn-main .border-btn,
  .fill-btn-main .fill-btn {
    font-size: 16px;
    line-height: 19px;
    padding: 9px 33px;
    height: 50px;
  }

  .logo {
    height: 77px;
    width: 100px;
    object-fit: contain;
  }
}

@media (min-device-width: 1280px) and (max-device-width: 1290px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .follow-right-img-main {
    display: none;
  }

  .follow-col1 {
    width: 100%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .follow-col2 {
    width: 100%;
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 20px;
  }

  .header-row {
    padding: 0px 20px;
  }

  .tokenomics-box .tokenomics-box-text {
    font-size: 19px;
    line-height: 32px;
  }

  .tokenomics-box .tokenomics-super-senior-text,
  .tokenomics-box .tokenomics-sulphurpoint-text {
    font-size: 19px;
    line-height: 22px;
  }

}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1279px) {
  @keyframes moveclouds {
    0% {
      transform: translateX(-200px) rotate(360deg);
      -webkit-transform: translateX(-200px) rotate(360deg);
    }

    100% {
      transform: translateX(430px) rotate(19deg);
      -webkit-transform: translateX(430px) rotate(19deg);
    }
  }

  .header-pos-fixed {
    position: fixed;
    background: #240736;
    top: 0;
    width: 100%;
    max-width: 300px;
    bottom: 0;
    z-index: 9999;
    left: -300px;
    transition: 0.5s all ease;
  }

  .header-nav-main {
    height: 100vh;
    padding: 20px 0;
  }

  .header-nav-main .nav-ul {
    flex-direction: column;
    gap: 0px;
  }

  .header-nav-main .nav-li {
    padding: 15px 20px;
    margin: 0px;
    max-width: 100%;
  }

  .header-nav-main .nav-link::before,
  .header-nav-main .nav-link::after,
  .header-nav-main .nav-li.active::before,
  .header-nav-main .nav-li.active::after {
    display: none;
  }

  .page-title-main .page-title {
    font-size: 45px;
    line-height: 70px;
  }

  .footer-main {
    margin-top: 100px;
  }

  .footer-containt-flex {
    gap: 20px;
  }

  .footer-social-icons {
    width: 55px;
    height: 55px;
  }

  .header-nav-main .nav-ul {
    flex-direction: column;
    gap: 0px;
  }

  .header-nav-main .nav-li {
    width: 100%;
    padding: 10px 20px;
  }

  .header-pos-fixed.open {
    left: 0px;
  }

  .overlay-mobile-details {
    background: #0000006b;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: none;
  }

  .overlay-mobile-details.overlay-open {
    display: block;
  }

  /* .header-btn-flex .mobile-hidden-btn {
    display: none;
  } */
  .responsive-none-grid {
    display: none;
  }

  .grid-none-open {
    display: block;
  }

  .header-btn-flex .btn-menu-header {
    display: block;
  }

  .header-nav-main .nav-li.active::before,
  .header-nav-main .nav-li.active::after {
    display: none;
  }
}

/* phone and tablets */
@media screen and (max-width: 991px) {

  .header-nav-main .nav-li.active::before,
  .header-nav-main .nav-li.active::after {
    display: none;
  }

  .responsive-none-grid {
    display: none;
  }

  .header-btn-flex .btn-menu-header {
    display: block;
    padding: 0;
  }

  .grid-none-open {
    display: block;
  }

  .header-btn-flex .mobile-hidden-btn {
    display: none;
  }

  .overlay-mobile-details {
    background: #0000006b;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: none;
  }

  .overlay-mobile-details.overlay-open {
    display: block;
  }

  .header-pos-fixed.open {
    left: 0px;
  }

  .header-nav-main .nav-ul {
    flex-direction: column;
    gap: 0px;
  }

  .header-nav-main .nav-li {
    width: 100%;
    padding: 10px 20px;
  }

  .header-pos-fixed {
    position: fixed;
    transition: 0.5s all ease;
    background: #240736;
    top: 0;
    width: 100%;
    max-width: 300px;
    bottom: 0;
    z-index: 9999;
    left: -300px;
  }

  .header-nav-main {
    height: 100vh;
    padding: 20px 0;
  }

  .header-nav-main .nav-link::before,
  .header-nav-main .nav-link::after,
  .header-nav-main .nav-li.active::before,
  .header-nav-main .nav-li.active::after {
    display: none;
  }

  .header-btn-flex .btn-menu-header,
  .header-nav-main .nav-li:nth-child(5),
  .header-nav-main .nav-li:last-child {
    display: block;
  }

  .sidebar-social-main {
    display: block;
  }

  .header-main {
    padding: 10px 0px;
  }

  .header-nav-main .nav-li.active .nav-link {
    color: var(--green);
  }
  .logo {
    height: 70px;
    width: 70px;
  }
}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .footer-logo {
    width: 130px;
    height: 130px;
  }

  .footer-para-main .footer-para {
    font-size: 15px;
  }

  .footer-copy-text-main {
    padding: 23px 0px 23px 0px;
    margin-top: 25px;
  }

  .footer-main {
    margin-top: 100px;
  }

  .footer-containt-flex {
    gap: 20px;
  }

  .footer-social-icons {
    width: 55px;
    height: 55px;
  }

  .page-title-main .page-title {
    font-size: 45px;
    line-height: 70px;
  }

  .body-overflow {
    overflow: hidden !important;
    height: 100vh;
  }

  .user-list-flex {
    align-items: flex-start;
    flex-direction: column;
  }

  .header-btn-flex {
    gap: 20px;
    padding-right: 10px;
  }

  .border-btn-main .border-btn,
  .fill-btn-main .fill-btn {
    font-size: 17px;
    line-height: 20px;
    padding: 14px 22px;
    height: 50px;
  }

}

/* phone */
@media (max-width: 767px) {
  .logo {
    height: 55px;
    width: 55px;
  }
  .lamp-two {
    left: 23%;
  }

  .lamp-two svg,
  .lamp-four svg {
    height: 51px;
  }

  @keyframes moveclouds {
    0% {
      transform: translateX(-200px) rotate(360deg);
      -webkit-transform: translateX(-200px) rotate(360deg);
      -moz-transform: translateX(-200px) rotate(360deg);
      -ms-transform: translateX(-200px) rotate(360deg);
      -o-transform: translateX(-200px) rotate(360deg);
    }

    100% {
      transform: translateX(260px) rotate(19deg);
      -webkit-transform: translateX(260px) rotate(19deg);
      -moz-transform: translateX(260px) rotate(19deg);
      -ms-transform: translateX(260px) rotate(19deg);
      -o-transform: translateX(260px) rotate(19deg);
    }
  }

  .in-view {
    animation: unset;
  }

  .follow-logo-bottm {
    position: absolute;
    bottom: -15px;
    right: 10px;
    transform: rotate(19deg);
  }

  .admin-sidebar-logo-main .sidebar-close-btn {
    display: block;
  }

  .dashboard-containt-main {
    padding: 88px 15px 24px 15px;
  }

  .admin-dashboard-inner-box {
    max-width: 100%;
  }

  .dashboard-content .admin-page-title {
    font-size: 20px;
    line-height: 25px;
  }

  .admin-dashboard-inner-box .admin-dash-price {
    font-size: 20px;
    line-height: 25px;
  }

  .export-btn-main .export-btn,
  .export-btn-main .export-btn:hover {
    margin-right: 0px;
  }

  .dashboard-content .user-list-page-title {
    margin-bottom: 20px;
  }

  .bergurmenu-icon {
    height: 20px;
    object-fit: contain;
    width: 20px;
  }

  .admin-header-icon-box {
    margin-right: 15px;
  }

  .admin-header-row .bergur-button {
    padding: 0px;
    min-width: 37px;
  }

  .dashboard-left-main {
    flex: 0 1;
    max-width: 0;
    width: 0;
  }

  .dashboard-right-main {
    margin-left: 0px;
  }

  .admin-sidebar-logo-main {
    display: flex;
    justify-content: space-between;
  }

  .close-icon {
    height: 23px;
    width: 23px;
    object-fit: contain;
    display: block;
  }

  .admin-sidebar-logo {
    order: initial;
    margin: initial;
  }

  .admin-header-main.active .admin-sidebar-logo {
    opacity: 1;
    display: block;
    height: 35px;
    margin-left: 10px;
    order: 1;
  }

  .admin-header-main.admin-header-deactive .admin-sidebar-logo {
    opacity: 1;
    margin-left: 10px;
    height: 35px;
  }

  .admin-header-main {
    left: 0;
  }

  .admin-header-deactive .admin-header-logo-main {
    flex-direction: row-reverse;
  }

  .filter-main .MuiDrawer-paper {
    min-width: 300px;
    max-width: 300px;
  }

  .progress-bar-text {
    right: 0px;
    top: -23px;
  }

  .body-overflow {
    overflow: hidden !important;
    height: 100vh;

  }

  .header-sidebar-logo {
    order: 2;
  }

  .modal .modal-inner {
    max-width: 350px;
  }

  .footer-containt-flex {
    gap: 20px;
    flex-direction: column;
    align-items: start;
  }

  .footer-para-main .footer-para {
    font-size: 16px;
    line-height: 28px;
  }

  .footer-social-media-main .footer-social-ul {
    justify-content: start;
    gap: 15px;
  }

  .footer-social-icons {
    height: 55px;
    width: 55px;
  }

  .scroll-top button {
    height: 45px;
    width: 45px;
  }

  .scroll-top {
    right: 10px;
  }

  .footer-main {
    margin-top: 70px;
  }

  .page-title-main .page-title {
    font-size: 30px;
    line-height: 50px;
  }

  .btn-menu-header .burgur-icon {
    height: 45px;
    width: 45px;
  }

  .home-hero-main {
    padding-top: 100px;
  }
}

@media (max-width: 321px) {
  .logo {
    height: 63px;
  }

  .follow-containt-main .follow-text {
    font-size: 41px;
    line-height: 62px;
  }

  .page-title-main.roadmap-section-title .page-title-para {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 28px;
  }

  .copy-icon {
    height: 20px;
    width: 20px;
  }

  .footer-copy-text-main .footer-copy-text {
    font-size: 19px;
    line-height: 24px;
  }

  .footer-logo {
    width: 124px;
    height: 112px;
  }
}


@media screen and (min-width: 1200px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio:0) {}


@media screen and (min-width: 767px) and (max-width: 925px) and (-webkit-min-device-pixel-ratio:0) {

  /* Your CSS rules for Safari with a width range between 767px and 925px */
  .about-section .about-row {
    display: flex;
    flex-direction: column;
  }



}

@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio:0) {

  /* Your CSS rules for Safari with a maximum width of 767px */
  .token-bone-animation {
    top: -25px;
  }

  .token-wallet-input-main {
    padding: 60px 0px;
  }

  .follow-icon-logo {
    height: 150px;
  }

}

@media screen and (max-width: 375px) and (-webkit-min-device-pixel-ratio:0) {
  .follow-icon-logo {
    height: 90px;
  }
}