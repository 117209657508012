
@media screen and (min-width: 1348px) and (max-width: 1599px) {
  /* follow css */

  .follow-lamp-details {
    max-width: 637px;
    display: flex;
  }

  .follow-left-img {
    height: 292px;
    object-position: bottom;
  }

  .lamp-one {
    bottom: -5px;
    left: 6px;
  }

  .lamp-two {
    bottom: -5px;
    left: 141px;
  }

  .lamp-two svg,
  .lamp-four svg {
    height: 70px;
  }

  .follow-containt-main {
    margin-top: 40px;
  }

  .follow-containt-main .follow-text {
    font-size: 50px;
    line-height: 80px;
    max-width: 370px;
  }

  .follow-social-main {
    margin-top: 30px;
  }

  .follow-social-main .follow-social-icons {
    height: 55px;
    width: 55px;
  }

  .follow-right-img-main .follow-right-img {
    height: 292px;
  }

  .lamp-four {
    left: 161px;
  }

  .follow-icon-logo {
    height: 175px;
  }

  /* .follow-icon-logo{
      max-width: 190px;
      height: 190px;
    } */

  /* follow css */

  .footer-main {
    margin-top: 170px;
  }
  .follow-icon-logo {
    /* position: absolute; */
  }
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  /* .follow-right-img-main{
    display: none;
  } */
  .follow-section {
    padding-top: 100px;
}
.follow-col1 {
  width: 100%;
  flex: 0 0 50%;
  max-width: 50%;
}
.follow-col2{
  width: 100%;
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 20px;
}
.follow-col3{
  width: 100%;
  flex: 0 0 20%;
  max-width: 20%;
}
.follow-left-img-main{
  padding-right: 10px;
}

@keyframes moveclouds{
  0%{
    transform: translateX(-200px) rotate(360deg);
    -webkit-transform: translateX(-200px) rotate(360deg);
    -moz-transform: translateX(-200px) rotate(360deg);
    -ms-transform: translateX(-200px) rotate(360deg);
    -o-transform: translateX(-200px) rotate(360deg);
}
  100%{
    transform: translateX(540px) rotate(19deg);
    -webkit-transform: translateX(540px) rotate(19deg);
    -moz-transform: translateX(540px) rotate(19deg);
    -ms-transform: translateX(540px) rotate(19deg);
    -o-transform: translateX(540px) rotate(19deg);
}
}

.follow-lamp-details {
  max-width: 616px;
}
.follow-icon-logo {
  height: 182px;
}
.follow-containt-main {
  margin-top: 0px;
}
.lamp-two {
  left: 100px;
}
.lamp-two svg, .lamp-four svg {
  height: 51px;
}
.follow-social-main .follow-social-icons {
  height: 55px;
  width: 55px;
}
.follow-social-main .follow-social-ul {
  gap: 20px;
}
.follow-social-main {
  margin-top: 20px;
}
}

@media screen and (min-width: 1200px) and (max-width: 1250px){
  .follow-right-img-main{
    display: none;
  }

  .follow-col1 {
    width: 100%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .follow-col2{
    width: 100%;
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 20px;
  }
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .follow-right-img-main{
    display: none;
  }
  .follow-section {
    padding-top: 100px;
}
.follow-col1 {
  width: 100%;
  flex: 0 0 60%;
  max-width: 60%;
}
.follow-col2{
  width: 100%;
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 20px;
}
.follow-icon-logo {
  height: 182px;
}
.follow-containt-main {
  margin-top: 37px;
}
.follow-containt-main .follow-text {
  font-size: 42px;
  line-height: 68px;
}
.follow-social-main .follow-social-icons {
  height: 55px;
  width: 55px;
}
.follow-social-main .follow-social-ul {
  gap: 20px;
}
.follow-social-main {
  margin-top: 20px;
}
.follow-lamp-details {
  max-width: 597px;
}
}

@media screen and (min-width: 768px) and (max-width: 991px){
  @keyframes moveclouds{
    0%{
      transform: translateX(-200px) rotate(360deg);
      -webkit-transform: translateX(-200px) rotate(360deg);
      -moz-transform: translateX(-200px) rotate(360deg);
      -ms-transform: translateX(-200px) rotate(360deg);
      -o-transform: translateX(-200px) rotate(360deg);
}
    100%{
      transform: translateX(345px) rotate(19deg);
      -webkit-transform: translateX(345px) rotate(19deg);
      -moz-transform: translateX(345px) rotate(19deg);
      -ms-transform: translateX(345px) rotate(19deg);
      -o-transform: translateX(345px) rotate(19deg);
}
  }
  .follow-right-img-main{
    display: none;
  }
  .follow-section {
    padding-top: 100px;
}
.follow-col1 {
  width: 100%;
  flex: 0 0 60%;
  max-width: 60%;
}
.follow-col2{
  width: 100%;
  flex: 0 0 40%;
  max-width: 40%;
  padding-left: 20px;
}
.follow-icon-logo {
  height: 112px;
}
.follow-containt-main {
  margin-top: 0px;
}
.lamp-two {
  left: 100px;
}
.lamp-two svg, .lamp-four svg {
  height: 51px;
}
.follow-containt-main .follow-text {
  font-size: 34px;
  line-height: 65px;
}
.follow-social-main .follow-social-icons {
  height: 55px;
  width: 55px;
}
.follow-social-main .follow-social-ul {
  gap: 20px;
}
.follow-social-main {
  margin-top: 20px;
}
.follow-lamp-details {
  max-width: 457px;
}
}

@media screen and (max-width: 767px){
  .follow-lamp-details {
    padding: 0px 10px;
    width: 100%;
    max-width: 100%;
}
  .follow-section {
    padding-top: 100px;
  }
  .follow-flex {
    flex-direction: column;
  }
  .follow-right-img-main{
    display: none;
    }
    .follow-icon-logo{
      height: 100px;
    }
    .follow-lamp-details {
      padding: 0px 10px;
  }
  .follow-containt-main {
    margin-top: 40px;
    padding: 0px 15px;
}
.follow-containt-main .follow-text {
  font-size: 51px;
  line-height: 70px;
}
.follow-social-main {
  margin-top: 30px;
}
.follow-social-main .follow-social-icons {
  height: 55px;
  width: 55px;
}
.follow-social-main .follow-social-ul {
  display: flex;
  gap: 15px;
}
}