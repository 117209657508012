* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}
html{
  overflow-x: hidden;
}

body {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: var(--main-black);
  background-color: var(--bg-color);
  overflow-x: hidden;
  scroll-behavior: smooth;
}

:root {
  --main-white: #fff;
  --primary-color: #111827;
  --secondary-color: #6b7280;
  --selected-color: #c85803;
  --main-border: #d1d5db85;
  --pink-brder: #d91ffb;
  --main-black: #000;
  --main-red: #b90005;
  --bg-color: #240736;
  --body-color: #f4f5f7;
  --off-white: #dbd4ce;
  --green: #00ee92;
  --gray: #adb5bd;
  --pink: #d91ffb;
  --yellow: #ffa447;
  --dark-blue: #111827;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url(../fonts/Poppins/Poppins-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(../fonts/Poppins/Poppins-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(../fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url(../fonts/Poppins/Poppins-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Doggies-Silhouette-Font-Regular";
  src: url(../fonts/doggies-silhouette-font/Doggies-Silhouette-Font.ttf)
    format("truetype");
}

@font-face {
  font-family: "Super-Senior";
  src: url(../fonts/SUPER-SENIOR.TTF) format("truetype");
}

@font-face {
  font-family: "SulphurPoint-Bold";
  src: url(../fonts/Sulphur_Point/SulphurPoint-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SulphurPoint-Regular";
  src: url(../fonts/Sulphur_Point/SulphurPoint-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SulphurPoint-Light";
  src: url(../fonts/Sulphur_Point/SulphurPoint-Light.ttf) format("truetype");
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.text-decoration-none {
  text-decoration: none !important;
}

/* end input css */

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
}

/* start input field design css */

.form-group .form-control input {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

.form-group .form-control input::placeholder {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.form-control fieldset {
  border: 1px solid #d1d5db;
  border-radius: 2px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d1d5db !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

/* end input field design css */

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: #eaac35;
  border-radius: 4px;
  width: 100%;
  max-width: 380px;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  padding: 10px 15px;
  text-transform: capitalize;
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular";
}

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Regular";
}

/* end switch css */

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 27px;
}

.pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: #114a65;
  color: #ffffff;
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: #114a65;
  color: #ffffff;
}

/* end pagination css */

/* start progrss bar css */

.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: #114a65;
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #111827;
  font-family: "Poppins-Medium";
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: #6b7280;
  font-family: "Poppins-Medium";
}

/* end progrss bar css */

/* start error text css */

.error-text {
  color: red;
  font-size: 12px;
  line-height: 15px;
  font-family: "Poppins-Regular";
}

/* end error text css */

/* / start dropdown css / */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-select .MuiSelect-select {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}

/* / end dropdown css / */

/* start common table title */

.table-title-main .table-title-name {
  color: #003d90;
  font-family: "Poppins-Bold";
  font-size: 17px;
  line-height: 20px;
}

.table-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.table-search-flex {
  display: flex;
}

/* end common table title */

.border-btn-main .border-btn {
  font-size: 20px;
  line-height: 24px;
  font-family: "Super-Senior";
  font-weight: 400;
  color: var(--main-white);
  text-transform: capitalize;
  padding: 17px 40px;
  border-radius: 20px;
  border: 2px solid var(--main-white);
  background-color: transparent;
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.43);
  box-shadow: 3px 3px 0px 0px #01faa3;
  transition: all 0.3s;
}

/* .border-btn-main .border-btn:hover {
  transform: scale(1.1);
  color: var(--pink);
  border: 2px solid var(--pink);
} */

.border-btn-main .border-btn:hover{
  transform: scale(1.1);
  color: var(--pink);
  border: 2px solid var(--main-white);
  box-shadow: 3px 3px 0px 0px var(--pink), 6px 4px 0px 0px var(--green),
    9px 5px 0px 0px var(--pink), 12px 6px 0px 0px var(--green),
    15px 8px 0px 0px var(--pink);
}

.fill-btn-main .fill-btn {
  font-size: 20px;
  line-height: 24px;
  font-family: "Super-Senior";
  font-weight: 400;
  color: var(--dark-blue);
  text-transform: capitalize;
  padding: 17px 40px;
  border-radius: 20px;
  background-color: var(--main-white);
  border: 2px solid var(--main-black);
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.43);
}

.fill-btn-main .fill-btn:hover {
  transform: scale(1.1);
  color: var(--pink);
  border: 2px solid var(--main-white);
  box-shadow: 3px 3px 0px 0px var(--pink), 6px 4px 0px 0px var(--green),
    9px 5px 0px 0px var(--pink), 12px 6px 0px 0px var(--green),
    15px 8px 0px 0px var(--pink);
}

.page-title-main .page-title {
  font-size: 70px;
  line-height: 80px;
  font-family: "Super-Senior";
  color: var(--main-white);
  text-align: center;
}

.page-title-main .page-title-para {
  font-size: 18px;
  line-height: 36px;
  font-weight: 400;
  color: var(--main-white);
  text-align: center;
  max-width: 863px;
  width: 100%;
  margin: auto;
}

.lineSvg {
  fill: none;
  /* stroke: red; */
  stroke: #c8202600;
}

.svg-animation-wrape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  right: 0;
  height: 3278px;
}
.svg-animation-wrape .lineSvg  {
  /* max-width: 600px;
      overflow: visible;
      margin-top: 60vh; */
  width: 100%;
  height: 100%;
}
.ball {
  fill: unset;
  height: 50px;
  width: 50px;
  visibility: hidden;
}

.line {
  fill: none;
  /* stroke: red; */
  stroke-width: 2px;
}

.header-logo-main {
  display: flex;
}



/* ///// */

.scroll-top{
  position: fixed;
    right: 30px;
    z-index: 999;
    bottom: 30px;
}

.scroll-top button {
  background: linear-gradient(178deg, #00d89f, #d429f9);
  min-width: unset;
  padding: 10px;
  border-radius: 50%;
  height: 55px;
  width: 55px;
}

.scroll-top .arrow-up {
  height: 25px;
}


.header-btn-flex .mobile-hidden-btn{
  display: block;
}

.header-btn-flex .btn-menu-header, .header-nav-main .nav-li:nth-child(5), .header-nav-main .nav-li:last-child{
  display: none;
}

.btn-menu-header .burgur-icon {
  height: 45px;
  width: 45px;
}

