@media screen and (min-width: 1600px) and (max-width: 1919px) {
    .about-para-main {
      margin-left: 0px;
    }
}

@media screen and (min-width: 1366px) and (max-width: 1599px){

    .about-para-main{
        max-width: 100%;
        margin-left: 0px;
        padding-left: 10px;
    }
    .about-para-main .about-para {
        font-size: 16px;
        line-height: 34px;
    }
    .about-img {
        width: 100%;
        height: 380px;
    }
    .about-dog-img {
        height: 464px;
    }
    .cloud-about-four img{
        width: 180px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {

.about-para-main{
    max-width: 100%;
    margin-left: 0px;
    padding-left: 10px;
  }
  .about-para-main .about-para {
    font-size: 16px;
    line-height: 34px;
  }
  .about-containt-main{
    max-width: 690px;
  }
  .about-dog-img {
    left: -55px;
    height: 450px;
  }

  .cloud-about-four img{
    width: 180px;
}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .about-para-main {
    margin-left: 0px;
    padding-left: 31px;
}
.about-img {
  width: 100%;
  object-fit: contain;
}
.about-dog-img {
  bottom: 5px;
  height: 283px;
  left: -12px;
}
.about-dog-rotated {
  position: absolute;
  right: 10%;
  bottom: 1px;
}
.about-coin {
  height: 120px;
  width: 120px;
}
.about-containt-main {
  position: relative;
  height: 100%;
  max-width: unset;
  width: 100%;
  display: flex;
  align-items: center;
}

.about-section-title .page-title {
  margin-bottom: 100px;
}
.about-para-main .about-para {
  margin-bottom: 20px;
}
}


@media screen and (min-width: 768px) and (max-width: 991px) {
  .about-para-main .about-para {
    margin-bottom: 20px;
  }
  .about-section {
    padding: 100px 0px 0px;
}
  .about-para-main {
    margin-left: 0px;
    max-width: 100%;
    padding-left: 0px;
}
.about-img {
  width: 100%;
  object-fit: contain;
}
.about-dog-img {
  bottom: 5px;
  height: 483px;
  left: -12px;
}

.about-dog-rotated {
  position: absolute;
  right: 10%;
  bottom: 1px;
}
.about-coin {
  height: 170px;
  width: 140px;
}

.about-containt-main {
  position: relative;
  height: 100%;
  max-width: unset;
  width: 100%;
  display: flex;
  align-items: center;
}

.about-section-title .page-title {
  margin-bottom: 90px;
}

.about-section::before {
  content: "";
  left: 216px;
  top: 27%;
  width: 400px;
  height: 400px;
}
.about-section::after {
  right: -220px;
  top: 66%;
  width: 300px;
}


}



@media screen and (max-width: 767px){
  .about-dog-rotated > div {
    transform: unset !important;
    opacity: 1 !important;
    -webkit-transform: unset !important;
    -moz-transform: unset !important;
    -ms-transform: unset !important;
    -o-transform: unset !important;
}
.about-img-main {
  max-width: 550px;
  margin: auto;
}
  .about-section{
    padding: 70px 0px 0px;
  }
  .about-section-title .page-title {
    margin-bottom: 50px;
}
.about-para-main {
  margin-left: 0px;
  margin-top: 30px;
  width: 100%;
  max-width: unset;
}

.about-dog-img {
  bottom: -18px;
  height: 380px;
  left: -36px;
}
.about-coin {
  height: 90px;
  width: 90px;
}
.about-para-main .about-para {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
}
}

@media screen and (max-width:511px){
  .about-dog-img {
    height: 330px;
  }
}

@media screen and (max-width:452px){
  .about-dog-img {
    height: 300px;
  }
}

@media screen and (max-width:386px){
  .about-dog-img {
    height: 250px;
}
}

@media screen and (max-width: 366px){
  .about-coin {
    height: 70px;
    width: 70px;
}
}

@media screen and (max-width: 281px){
  .about-dog-img {
    height: 190px;
    left: -20px;
}
.about-dog-rotated {
  right: 9%;
}
}