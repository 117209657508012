@media (max-width: 1798px) {
  .container.roadmap-container {
    max-width: 1500px;
    padding-left: 80px;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .roadmap-bg-section::before {
    background-size: 135px 568px;
    width: 135px;
    height: 568px;
    background-position: right;
    bottom: -12px;
  }
  .roadmap-bg-section::after {
    background-size: cover;
    width: 138px;
    height: 595px;
    bottom: 11px;
  }
  .container.roadmap-container {
    max-width: 1290px;
  }
  .roadmap-box-main .roadmap-box-para {
    font-size: 14px;
    line-height: 24px;
  }
  .roadmap-box-main .roadmap-box-title {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .roadmap-bg-section::before {
    width: 130px;
    /* height: 718px; */
    background-position: right;
  }
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .left-fox-roadmap {
    left: 23%;
  }
  .right-fox-roadmap {
    right: 23%;
  }

  .roadmap-bg-section::after {
    width: 105px;
  }
  .roadmap-bg-section::before {
    width: 105px;
    background-position: right;
  }
  .container.roadmap-container {
    max-width: 880px;
  }
  .roadmap-section {
    padding: 259px 0px 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .container.roadmap-container {
    max-width: 630px;
    padding-left: 45px;
  }
  .roadmap-bg-section::before {
    width: 95px;
    height: 568px;
    background-position: right;
}
.roadmap-bg-section::after {
  width: 128px;
  height: 636px;
}
.coin-road-details {
  height: 150px;
  width: 150px;
}
.right-fox-roadmap {
  bottom: 21%;
  right: 19%;
}
.left-fox-roadmap{
  bottom: 21%;
  left: 19%;
}
.coin-roadmap-center {
  position: absolute;
  top: 9%;
}

}

@media screen and (max-width: 767px){
  .container.roadmap-container {
    padding-left: 15px;
}
.roadmap-bg-section::after,.roadmap-bg-section::before{
  display: none;
}

.coin-roadmap-center {
  top: 11%;
  margin-left: -20px;
}
.coin-road-details {
  height: 120px;
  width: 120px;
}

.right-fox-roadmap .animated-svg,  .left-fox-roadmap .animated-svg {
  height: 461px; max-width: max-content;
}

.left-fox-roadmap {
  left: 0%;
}
.right-fox-roadmap{
  right: 0%;
}
}

@media screen and (max-width: 361px){
  .page-title-main.roadmap-section-title .page-title-para {
    margin-bottom: 20px;
}

.page-title-main .page-title-para {
    font-size: 17px;
    line-height: 31px;
}
}


@media screen and (max-width: 321px){
  .right-fox-roadmap .animated-svg, .left-fox-roadmap .animated-svg {
    height: 381px;
    max-width: max-content;
}

.follow-containt-main .follow-text {
  font-size: 41px;
  line-height: 60px;
}
}