.roadmap-section-title .page-title {
  margin-bottom: 19px;
}

.roadmap-bg-container {
  max-width: 1460px;
}

.roadmap-box-main {
  background-color: var(--pink);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 35px 17px;
}

.roadmap-box-main .roadmap-box-title {
  font-size: 33px;
  line-height: 40px;
  color: var(--main-white);
  font-weight: 600;
  margin-bottom: 18px;
  text-align: center;
}

.roadmap-box-main .roadmap-box-para {
  font-size: 18px;
  line-height: 24px;
  color: var(--main-white);
  font-weight: 400;
  text-align: center;
}

.roadmap-bg-section {
  position: relative;
  background-image: url(../../../../../assets/images/svg/roadmapimg.svg);
  width: 100%;
  height: 812px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: bottom center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  max-width: 1920px;
  margin: auto;
  /* background-attachment: fixed; */
}

.roadmap-bg-section::before {
  content: "";
  background: url("../../../../../assets/images/svg/leftbuiling.svg");
  background-size: 185px 718px;
  width: 185px;
  height: 718px;
  position: absolute;
  left: 0;
  z-index: 99;
  bottom: 10px;
}

.roadmap-bg-section::after {
  content: "";
  background: url("../../../../../assets/images/svg/rightbuiling.svg");
  background-size: 198px 646px;
  width: 198px;
  height: 646px;
  position: absolute;
  right: 0;
  z-index: 99;
  bottom: 10px;
}

.roadmap-section {
  position: relative;
  padding: 159px 0px 0px;
  max-width: 1920px;
  margin: auto;
}

.page-title-main.roadmap-section-title .page-title-para {
  margin-bottom: 49px;
}

.roadmap-section::after {
  position: absolute;
  content: "";
  height: 350px;
  width: 300px;
  top: 6%;
  left: 0;
  right: 0;
  z-index: -1;
  margin: auto;
  filter: blur(160px);
  background: linear-gradient(174deg, #d91ffb 35%, #00ee92 90%);
  animation: crescendo 1.5s alternate infinite ease-in;
  -webkit-animation: crescendo 1.5s alternate infinite ease-in;
}

@keyframes crescendo {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  50% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}

/* .roadmap-section::after{
      content: '';
      position: absolute;
      right: 0px;
      background-image: url(../../../../../assets/images/svg/roadmap-left-building.svg);
      height: 644px;
      width: 182px;
      background-repeat: no-repeat;
      top: 0px;
}
.roadmap-section::before{
      content: '';
      position: absolute;
      left: 0px;
      background-image: url(../../../../../assets/images/svg/roadmap-right-building.svg);
      height: 717px;
      width: 100%;
      background-repeat: no-repeat;
} */
/* .roadmap-slider-main {
      display: flex;
      justify-content: center;
      align-items: flex-end;
  } */
.container.roadmap-container {
  margin: 0px auto;
  max-width: 1600px;
  padding-left: 80px;
}

.roadmap-slider-main {
  margin-bottom: 70px;
  /* margin-left: -70px; */
}

.roadmap-wave-img {
  position: absolute;
  bottom: 29%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.wave-details {
  background-image: url("../../../../../assets/images/svg/waveroadmap.svg");
  width: 100%;
  height: 230px;
}

/* @keyframes wave {
      0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(100%);
        } 
    } */

.right-fox-roadmap {
  position: absolute;
  bottom: 20%;
  right: 33%;
}

.left-fox-roadmap {
  position: absolute;
  bottom: 20%;
  left: 33%;
}

.animated-svg #animated-path {
  opacity: 0;
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  animation: fillAnimation 3s ease infinite;
  -webkit-animation: fillAnimation 3s ease infinite;
}

@keyframes fillAnimation {
  0% {
    stroke-dashoffset: 1500;
    opacity: 0;
  }
  50% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 1500;
    opacity: 0;
  }
}

.coin-roadmap-center {
  position: absolute;
  top: 3%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  margin-left: -26px;
  /* animation: scale1 2s infinite; */
  transition: all 0.2s ease-out;
}

@keyframes scale1 {
  0% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
  100% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}
}
