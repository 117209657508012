.tokenomics-section {
  padding: 117px 0px 0px;
}

.tokenomics-flex-box-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 19px;
  margin-bottom: 80px;
}

.tokenomics-box {
  /* border: 1px solid var(--pink-brder); */
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 38px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s ease all;
  position: relative;
  background: #240736;
}

.tokenomics-box::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -1.5px;
  background: linear-gradient(123deg, #d91ffb 50%, #ffffff7d 20%, #00ee92 50%);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: glower 20s linear infinite;
  border-radius: 20px;
  -webkit-animation: glower 20s linear infinite;
}

@keyframes glower {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.tokenomics-box:hover {
  background: #240736;
  transform: translate(10px, -20px);
}

.tokenomics-box::after {
  content: "";
  background: #240736;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: 21px;
  z-index: -1;
}

.tokenomics-box .tokenomics-box-text {
  font-size: 19px;
  line-height:30px;
  color: var(--main-white);
  font-family: "SulphurPoint-Regular";
  font-weight: 400;
  text-align: center;
}

/* .tokenomics-box:hover .tokenomics-box .tokenomics-box-text {
      font-weight: 800;
} */

.tokenomics-box .tokenomics-sulphurpoint-text {
  font-family: "SulphurPoint-Regular";
  font-weight: 700;
  font-size: 19px;
  line-height: 27px;
  color: var(--main-white);
  text-align: center;
}

.tokenomics-box .tokenomics-super-senior-text {
  font-family: "SulphurPoint-Regular";
  font-size: 19px;
  line-height: 27px;
  color: var(--main-white);
  text-align: center;
  margin-bottom: 10px;
}

.token-address-contait-main .tokenomics-token-text {
  font-size: 40px;
  line-height: 46px;
  color: var(--main-white);
  font-family: "Super-Senior";
  text-align: center;
}

.tokenomics-section-title .page-title {
  margin-bottom: 140px;
}

.token-copy-box {
  font-size: 24px;
  line-height: 30px;
  color: var(--main-white);
  font-family: "Super-Senior";
  text-align: center;
  max-width: 468px;
}

.token-wallet-input-main input::placeholder {
  font-size: 24px;
  line-height: 30px;
  color: var(--main-white);
  font-family: "Super-Senior";
  text-align: center;
}

.token-wallet-input-main {
  /* position: relative;
      background-image: url(../../../../../assets/images/svg/token-bg.svg);
      height: 265px;
      width: 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      margin: -50px auto 0px;
      animation: tilt-shaking 0.5s infinite;
      -webkit-animation: tilt-shaking 0.5s infinite; */
}

.copy-icon {
  height: 38px;
  width: 38px;
}

.token-wallet-input-main .token-copy-btn {
  background-color: transparent;
  padding: 0px;
  min-width: max-content;
}

.tokenomics-img {
  width: 100%;
  max-width: 532px;
  height: 545px;
  object-fit: contain;
}

.tokenomics-img-main {
  margin-left: 98px;
  position: relative;
}

.token-address-contait-main {
  position: relative;
}

.token-copy-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 37px;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

  25% {
    transform: rotate(1deg);
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    -o-transform: rotate(1deg);
}

  50% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

  75% {
    transform: rotate(-1deg);
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
}

  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dasharray: 2230px;
    stroke-dashoffset: 2230px;
  }

  1% {
    stroke-dashoffset: 2230px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 2230px;
  }
}

.star1 {
  animation: animate-svg-stroke-1 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
  fill: #d91ffb;
  stroke: #00ee92;
  stroke-width: 2px;
  stroke-miterlimit: 6;
  -webkit-animation: animate-svg-stroke-1 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both;
}

.token-wallet-input-main {
  padding: 70px 0px;
  position: relative;
  z-index: 9;
}

.token-bone-animation {
  position: absolute;
  top: 0;
}

.copied-tooltip {
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: end;
  bottom: -23px;
  color: white;
  transform: translateX(-50%);
}

.smoke-box {
  position: absolute;
  right: 46%;
  top: -18px;
}

#chimney-smoke-box {
  height: 50px;
  position: absolute;
  width: 40px;
}

@keyframes smoke {
  0% {
    opacity: 1;
    border-radius: 50%;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
  80% {
    transform: translate3d(40px, -150px, 0px) rotate(20deg) skew(-30deg)
      scale(2);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -ms-filter: blur(5px);
    -o-filter: blur(5px);
    border-radius: 50%;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes smoke {
  0% {
    opacity: 1;
    border-radius: 50%;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}
  80% {
    transform: translate3d(40px, -150px, 0px) rotate(20deg) skew(-30deg)
      scale(2);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -ms-filter: blur(5px);
    -o-filter: blur(5px);
    border-radius: 50%;
  }
  100% {
    opacity: 0;
  }
}

.chimney-smoke {
  animation: smoke 3.5s infinite ease-out;
  -webkit-animation: smoke 3.5s infinite ease-out;
  background: #2c2c2c;
  background: -webkit-repeating-linear-gradient(
    90deg,
    transparent 0%,
    #ffffff7a 50%
  );
  background: -o-repeating-linear-gradient(
    90deg,
    transparent 0%,
    #ffffff7a 50%
  );
  background: -moz-repeating-linear-gradient(
    90deg,
    transparent 0%,
    #ffffff7a 50%
  );
  background: repeating-linear-gradient(90deg, transparent 0%, #ffffff7a 50%);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
  height: 100%;
  position: relative;
  width: 100%;
}

#chimney-smoke2 {
  top: -55px;
  animation-delay: 0.5s;
}
#chimney-smoke3 {
  top: -105px;
  animation-delay: 1s;
}
#chimney-smoke4 {
  top: -155px;
  animation-delay: 1.5s;
}
#chimney-smoke5 {
  top: -205px;
  animation-delay: 2.5s;
}
#chimney-smoke6 {
  top: -255px;
  animation-delay: 3s;
}

.token-copy-rela {
  position: relative;
}

.tokenomics-box:hover .tokenomics-box-text,
.tokenomics-box:hover .tokenomics-box-text {
  font-weight: bold;
}
.tokenomics-box:hover .tokenomics-sulphurpoint-text,
.tokenomics-box:hover .tokenomics-super-senior-text {
  font-weight: bold;
}
