.about-section {
  padding: 159px 0px 0px;
  position: relative;
}

.about-section::after {
  content: "";
  position: absolute;
  right: -220px;
  top: 26%;
  background: linear-gradient(174deg, #d91ffb 30%, #00ee92);
  border-radius: 50%;
  width: 340px;
  filter: blur(112px);
  height: 410px;
}

.about-section::before {
  content: "";
  position: absolute;
  /* left: 19%; */
  left: 356px;
  top: 37%;
  width: 431px;
  filter: blur(104px);
  height: 450px;
  background: linear-gradient(174deg, #d91ffb, #00ee92);
  border-radius: 50%;
}

.about-img {
  width: 100%;
  /* width: 771px;
      height: 531px; */
  object-fit: contain;
}

.about-para-main .about-para {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: var(--main-white);
  margin-bottom: 53px;
}

.about-coin {
  height: 179px;
  width: 170px;
  object-fit: contain;
  transform: rotate(19deg);
}

.about-dog-rotated{
      position: absolute;
      right: 14%;
      z-index: 1;
      bottom: -10px;
}

.about-img-main {
  position: relative;
}

.about-para-main {
  max-width: 589px;
  width: 100%;
  margin-left: 96px;
  margin-top: 60px;
}

.about-section-title .page-title {
  margin-bottom: 129px;
}

.about-dog-img {
  position: absolute;
  bottom: -35px;
  height: 534px;
  width: 100%;
  left: -66px;
  object-fit: contain;
}

/*  */

.about-containt-main {
  position: relative;
  max-width: 771px;
}

.cloud-details .cloud-about-one {
  position: absolute;
  top: 0%;
}



.cloud-details .cloud-about-two {
  position: absolute;
  top: 0%;
  z-index: -1;
}
.cloud-details .cloud-about-three {
  position: absolute;
  top: 0%;
  z-index: -1;
}

.cloud-details .cloud-about-four {
  position: absolute;
  top: 0%;
  z-index: -1;
}

.cloud-details{
  display: none;
}