@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .follow-icon-logo {
    height: 190px;
  }
}

@media screen and (min-width: 1348px) and (max-width: 1599px) {
  .hero-wif-12 {
    right: 35%;
  }
  @keyframes hero-wif-12 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }

    50% {
      opacity: 0.4;
    }

    75% {
      opacity: 0.6;
    }

    100% {
      top: 70%;
      right: 35%;
      opacity: 1;
    }
  }
  .hero-wif-11 {
    right: 28%;
  }
  @keyframes hero-wif-11 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }

    25% {
      opacity: 0.11;
    }

    50% {
      opacity: 0.21;
    }

    75% {
      opacity: 0.31;
    }

    100% {
      top: 60%;
      right: 28%;
      opacity: 0.41;
    }
  }
  .hero-wif-10 {
    right: 23%;
  }
  @keyframes hero-wif-10 {
    0% {
      top: 70%;
      right: 42%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.15;
    }

    75% {
      opacity: 0.2;
    }

    100% {
      top: 90%;
      right: 23%;
      opacity: 0.26;
    }
  }
  .hero-wif-8 {
    right: 12%;
  }

  @keyframes hero-wif-8 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0.3;
    }

    100% {
      top: 70%;
      right: 12%;
      opacity: 0.4;
    }
  }
  .hero-wif-7 {
    right: 5%;
  }
  @keyframes hero-wif-7 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0.3;
    }

    100% {
      top: 50%;
      right: 5%;
      opacity: 0.4;
    }
  }
  .hero-wif-6 {
    left: 41%;
  }
  @keyframes hero-wif-6 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }

    50% {
      opacity: 0.4;
    }

    75% {
      opacity: 0.6;
    }

    100% {
      top: 72%;
      left: 41%;
      opacity: 1;
    }
  }
  .hero-wif-1 {
    font-size: 84px;
  }
  .hero-wif-2 {
    font-size: 70px;
  }
  .hero-wif-4 {
    top: 88%;
    left: 33%;
  }
  @keyframes hero-wif-4 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.15;
    }

    75% {
      opacity: 0.2;
    }

    100% {
      top: 88%;
      left: 33%;
      opacity: 0.26;
    }
  }
  .hero-dog-img {
    width: 527px;
    height: 570px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {
  /* body {
    background-color: blue;
  } */
  .hero-containt-main .hero-titles {
    font-size: 121px;
    line-height: 130px;
  }
  .hero-dog-img {
    width: 470px;
    height: 560px;
  }
  .hero-wif-12 {
    right: 34%;
  }
  @keyframes hero-wif-12 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }

    50% {
      opacity: 0.4;
    }

    75% {
      opacity: 0.6;
    }

    100% {
      top: 70%;
      right: 34%;
      opacity: 1;
    }
  }
  .hero-wif-11 {
    right: 26%;
    top: 57%;
  }
  @keyframes hero-wif-11 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }

    25% {
      opacity: 0.11;
    }

    50% {
      opacity: 0.21;
    }

    75% {
      opacity: 0.31;
    }

    100% {
      right: 26%;
      top: 57%;
      opacity: 0.41;
    }
  }
  .hero-wif-10 {
    right: 24%;
    font-size: 45px;
    line-height: 44px;
    top: 91%;
  }

  @keyframes hero-wif-10 {
    0% {
      top: 70%;
      right: 42%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.15;
    }

    75% {
      opacity: 0.2;
    }

    100% {
      top: 91%;
      right: 24%;
      opacity: 0.26;
    }
  }

  .hero-wif-8 {
    right: 12%;
    font-size: 60px;
    line-height: 70px;
  }

  @keyframes hero-wif-8 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0.3;
    }

    100% {
      top: 70%;
      right: 12%;
      opacity: 0.4;
    }
  }

  .hero-wif-7 {
    right: 5%;
    top: 48%;
    font-size: 75px;
    line-height: 103px;
  }

  @keyframes hero-wif-7 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0.3;
    }

    100% {
      right: 5%;
      top: 48%;
      opacity: 0.4;
    }
  }

  .hero-wif-6 {
    left: 40%;
    top: 70%;
  }

  .hero-wif-1 {
    font-size: 74px;
  }
  .hero-wif-4 {
    top: 91%;
    font-size: 45px;
  }

  @keyframes hero-wif-4 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.15;
    }

    75% {
      opacity: 0.2;
    }

    100% {
      top: 91%;
      left: 31%;
      opacity: 0.26;
    }
  }
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section {
    background-position: center;
  }
  .hero-containt-main .hero-titles {
    font-size: 111px;
    line-height: 120px;
  }
  .hero-dog-img {
    width: 400px;
    height: 410px;
  }
  .hero-main-img-box {
    margin: -44px auto auto auto;
  }
  .hero-wif-12 {
    font-size: 25px;
    line-height: 30px;
    top: 71%;
    right: 33%;
  }

  @keyframes hero-wif-12 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }
    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.6;
    }
    100% {
      top: 71%;
      right: 33%;
      opacity: 1;
    }
  }
  .hero-wif-11 {
    font-size: 30px;
    line-height: 36px;
    top: 61%;
    right: 25%;
  }

  @keyframes hero-wif-11 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }

    25% {
      opacity: 0.11;
    }
    50% {
      opacity: 0.21;
    }
    75% {
      opacity: 0.31;
    }
    100% {
      top: 61%;
      right: 25%;
      opacity: 0.41;
    }
  }

  .hero-wif-10 {
    font-size: 40px;
    line-height: 39px;
    top: 90%;
    right: 20%;
  }

  @keyframes hero-wif-10 {
    0% {
      top: 70%;
      right: 42%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.15;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      top: 90%;
      right: 20%;
      opacity: 0.26;
    }
  }

  .hero-wif-9 {
    font-size: 47px;
    line-height: 70px;
  }

  .hero-wif-8 {
    font-size: 50px;
    line-height: 90px;
    top: 70%;
    right: 7%;
  }

  @keyframes hero-wif-8 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.2;
    }
    75% {
      opacity: 0.3;
    }
    100% {
      top: 70%;
      right: 7%;
      opacity: 0.4;
    }
  }
  .hero-wif-7 {
    font-size: 65px;
    line-height: 75px;
    top: 50%;
    right: 0%;
  }
  @keyframes hero-wif-7 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }
    25% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.2;
    }
    75% {
      opacity: 0.3;
    }
    100% {
      top: 50%;
      right: 0%;
      opacity: 0.4;
    }
  }
  .hero-wif-6 {
    font-size: 25px;
    line-height: 30px;
    top: 72%;
    left: 39%;
  }
  @keyframes hero-wif-6 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.6;
    }
    100% {
      top: 72%;
      left: 39%;
      opacity: 1;
    }
  }
  .hero-wif-1 {
    font-size: 65px;
    line-height: 70px;
    top: 65%;
    left: 8%;
  }

  .hero-wif-2 {
    font-size: 47px;
    line-height: 79px;
    top: 79%;
    left: 20%;
  }

  .hero-wif-3 {
    font-size: 36px;
    line-height: 55px;
    top: 49%;
    left: 22%;
  }

  @keyframes hero-wif-3 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }
    25% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.2;
    }
    75% {
      opacity: 0.3;
    }
    100% {
      top: 49%;
      left: 22%;
      opacity: 0.4;
    }
  }

  .hero-wif-4 {
    font-size: 32px;
    line-height: 42px;
    top: 87%;
    left: 31%;
  }
  .hero-wif-5 {
    font-size: 30px;
    line-height: 39px;
    top: 60%;
    left: 30%;
  }

  @keyframes hero-wif-5 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.11;
    }
    50% {
      opacity: 0.21;
    }
    75% {
      opacity: 0.31;
    }
    100% {
      top: 60%;
      left: 30%;
      opacity: 0.41;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hero-containt-main .hero-titles {
    font-size: 80px;
    line-height: 105px;
    letter-spacing: -4px;
  }
  .hero-main-img-box {
    margin: -45px auto auto auto;
  }
  .hero-dog-img {
    width: 350px;
    height: 350px;
  }
  .hero-section {
    padding-top: 15px;
    background-position: center;
  }
  .hero-wif-1 {
    font-size: 50px;
    line-height: 70px;
  }
  .hero-wif-2 {
    font-size: 30px;
    line-height: 40px;
  }
  .hero-wif-3 {
    font-size: 40px;
    line-height: 55px;
  }
  .hero-wif-4 {
    font-size: 25px;
    line-height: 49px;
  }
  .hero-wif-5 {
    font-size: 25px;
    line-height: 37px;
    top: 62%;
    left: 31%;
  }
  .hero-wif-6 {
    font-size: 20px;
    line-height: 30px;
    top: 72%;
    left: 38%;
  }

  @keyframes hero-wif-6 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.6;
    }
    100% {
      top: 72%;
      left: 38%;
      opacity: 1;
    }
  }

  .hero-wif-7 {
    font-size: 50px;
    line-height: 60px;
    top: 50%;
    right: -2%;
  }

  @keyframes hero-wif-7 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.2;
    }
    75% {
      opacity: 0.3;
    }
    100% {
      top: 50%;
      right: -2%;
      opacity: 0.4;
    }
  }
  .hero-wif-8 {
    font-size: 45px;
    line-height: 55px;
    top: 70%;
    right: 6%;
  }
  @keyframes hero-wif-8 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.2;
    }
    75% {
      opacity: 0.3;
    }
    100% {
      top: 70%;
      right: 6%;
      opacity: 0.4;
    }
  }
  .hero-wif-9 {
    font-size: 30px;
    line-height: 40px;
  }
  .hero-wif-10 {
    font-size: 30px;
    line-height: 49px;
    top: 90%;
    right: 22%;
  }

  @keyframes hero-wif-10 {
    0% {
      top: 70%;
      right: 42%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.15;
    }
    75% {
      opacity: 0.2;
    }
    100% {
      top: 90%;
      right: 22%;
      opacity: 0.26;
    }
  }
  .hero-wif-12 {
    font-size: 20px;
    line-height: 30px;
    top: 70%;
    right: 30%;
  }

  .hero-wif-11{
    font-size: 30px;
    line-height: 39px;
    top: 55%;
    right: 22%;
  }

  @keyframes hero-wif-11{
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
  }
  
  25% {
      opacity: 0.11;
  }
  50% {
      opacity: 0.21;
  }
  75% {
      opacity: 0.31;
  }
  100% {
    top: 55%;
    right: 22%;
      opacity: 0.41;
  }
  }

  @keyframes hero-wif-12 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.4;
    }
    75% {
      opacity: 0.6;
    }
    100% {
      top: 70%;
      right: 30%;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero-containt-main .hero-subtitle-text {
    font-size: 25px;
    line-height: 48px;
  }
  .hero-containt-main .hero-titles {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -2px;
    word-spacing: -11px;
  }
  .hero-dog-img {
    width: 250px;
    height: 250px;
  }
  .hero-main-img-box {
    margin: -25px auto auto auto;
  }
  .hero-wif-1,
  .hero-wif-2,
  .hero-wif-3,
  .hero-wif-7 {
    font-size: 30px;
    line-height: 55px;
  }
  @keyframes hero-wif-1 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0;
    }

    100% {
      top: 65%;
      left: 8%;
      opacity: 0;
    }
  }
  @keyframes hero-wif-2 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.15;
    }

    75% {
      opacity: 0;
    }

    100% {
      top: 79%;
      left: 20%;
      opacity: 0;
    }
  }
  @keyframes hero-wif-3 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0;
    }

    100% {
      top: 52%;
      left: 11%;
      opacity: 0;
    }
  }
  .hero-wif-3 {
    top: 52%;
    left: 11%;
  }
  .hero-wif-5 {
    font-size: 25px;
    line-height: 35px;
    top: 60%;
    left: 12%;
  }
  @keyframes hero-wif-5 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.11;
    }

    50% {
      opacity: 0.21;
    }

    75% {
      opacity: 0.31;
    }

    100% {
      top: 60%;
      left: 12%;
      opacity: 0.41;
    }
  }

  .hero-wif-4 {
    font-size: 30px;
    line-height: 45px;
    top: 92%;
    left: 14%;
  }

  @keyframes hero-wif-4 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.15;
    }

    75% {
      opacity: 0.2;
    }

    100% {
      top: 92%;
      left: 14%;
      opacity: 0.26;
    }
  }

  .hero-wif-6 {
    font-size: 19px;
    line-height: 25px;
    top: 75%;
    left: 30%;
  }

  @keyframes hero-wif-6 {
    0% {
      top: 70%;
      left: 50%;
      opacity: 0;
    }

    25% {
      opacity: 0.2;
    }

    50% {
      opacity: 0.4;
    }

    75% {
      opacity: 0.6;
    }

    100% {
      top: 75%;
      left: 30%;
      opacity: 1;
    }
  }

  .hero-wif-7 {
    top: 55%;
    right: 0%;
  }
  @keyframes hero-wif-7 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0;
    }

    100% {
      top: 55%;
      right: 0%;
      opacity: 0;
    }
  }
  .hero-wif-8 {
    font-size: 25px;
    line-height: 50px;
    top: 70%;
    right: 3%;
  }
  @keyframes hero-wif-8 {
    0% {
      top: 70%;
      right: 38%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0;
    }

    100% {
      top: 70%;
      right: 17%;
      opacity: 0;
    }
  }
  .hero-wif-9 {
    font-size: 25px;
    line-height: 35px;
  }
  @keyframes hero-wif-9 {
    0% {
      top: 70%;
      right: 42%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    75% {
      opacity: 0;
    }

    100% {
      top: 93%;
      right: 3%;
      opacity: 0;
    }
  }
  .hero-wif-10 {
    font-size: 25px;
    line-height: 49px;
    top: 94%;
    right: 1%;
  }
  @keyframes hero-wif-10 {
    0% {
      top: 70%;
      right: 42%;
      opacity: 0;
    }

    25% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.15;
    }

    75% {
      opacity: 0.2;
    }

    100% {
      top: 94%;
      right: 1%;
      opacity: 0.26;
    }
  }
  .hero-wif-12 {
    font-size: 19px;
    line-height: 29px;
    top: 77%;
    right: 19%;
  }
  @keyframes hero-wif-12 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }
  
    25% {
      opacity: 0.2;
    }
  
    50% {
      opacity: 0.4;
    }
  
    75% {
      opacity: 0.6;
    }
  
    100% {
      top: 77%;
      right: 19%;
      opacity: 1;
    }
  }
  .hero-wif-11 {
    font-size: 25px;
    line-height: 35px;
    top: 60%;
    right: -1%;
  }
  @keyframes hero-wif-11 {
    0% {
      top: 70%;
      right: 43%;
      opacity: 0;
    }

    25% {
      opacity: 0.11;
    }

    50% {
      opacity: 0.21;
    }

    75% {
      opacity: 0.31;
    }

    100% {
      top: 60%;
      right: -1%;
      opacity: 0.41;
    }
  }
  .scroll-down-img-box svg {
    height: 30px;
    width: 30px;
    display: none;
  }
}


@media screen and (max-width: 366px){

.hero-containt-main .hero-titles {
  font-size: 35px;
}
}