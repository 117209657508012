.header-main {
  padding: 37px 0px;
  transition: 0.2s all ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
  -ms-transition: 0.2s all ease;
  -o-transition: 0.2s all ease;
}

.home-hero-main {
  padding-top: 151px;
}

.sticky {
  background: #240736;
  padding: 15px 0px;
}

.header-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
} 

.logo {
  height: 93px;
  width: 100px;
  object-fit: contain;
}

.header-nav-main .nav-ul {
  display: flex;
  list-style-type: none;
  gap: 86px;
  margin: 0px;
  padding: 0px;
}
.header-nav-main .nav-li {
  padding: 0px;
  margin: 0px;
  max-width: max-content;
}

.header-nav-main .nav-link {
  font-size: 20px;
  line-height: 24px;
  font-family: "Super-Senior";
  color: var(--main-white);
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.header-nav-main .nav-link::before {
      content: "";
      display: block;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  height: 4px;
  background-color: var(--pink);
  transform: translateX(-50%);
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  opacity: 0;
}

.header-nav-main .nav-link.active::before {
  content: "";
  display: block;
position: absolute;
left: 50%;
top: -15px;
width: 100%;
height: 4px;
background-color: var(--pink);
transform: translateX(-50%);
-webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
-o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
opacity: 0;
}

.header-nav-main .nav-li.active::before {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: -15px;
  width: 100%;
  height: 4px;
  background-color: var(--pink);
  transform: translateX(-50%);
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.header-nav-main .nav-li.active::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 100%;
  height: 4px;
  background-color: var(--green);
  transform: translateX(-50%);
  -webkit-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


/* .header-nav-main .nav-link.active::before {
  opacity: 1;
  top: -15px;
}

.header-nav-main .nav-link.active::after{
      opacity: 1;
      bottom: -15px;
} */

.header-nav-main .nav-link:hover::before {
  opacity: 1;
  top: -15px;
}

.header-nav-main .nav-link:hover::after{
      opacity: 1;
      bottom: -15px;
}

.header-nav-main .nav-link::after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 100%;
      height: 4px;
      background-color: var(--green);
      transform: translateX(-50%);
      -webkit-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -o-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      opacity: 0;
}

.header-btn-flex {
  display: flex;
  justify-content: flex-end;
  gap: 39px;
}

.footer-main {
  border-top: 3px solid var(--pink);
  padding: 56px 0px 0px 0px;
  background-color: #17001c;
  margin-top: 227px;
}

.footer-logo {
  width: 154px;
  height: 162px;
  object-fit: contain;
}

.footer-containt-flex {
  display: flex;
  align-items: center;
  gap: 54px;
}

.footer-para-main {
  max-width: 479px;
  width: 100%;
}

.footer-para-main .footer-para {
  font-size: 18px;
  line-height: 31px;
  color: var(--main-white);
  font-weight: 400;
}

.footer-social-media-main .footer-social-ul {
  display: flex;
  gap: 30px;
  list-style-type: none;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}
.footer-social-media-main .footer-social-li {
  max-width: max-content;
  padding: 0px;
  margin: 0px;
}

.footer-social-media-main .footer-social-link {
  text-decoration: none;
}

.footer-social-icons {
  height: 76px;
  width: 76px;
  object-fit: contain;
  transition: 0.5s all;
}
.footer-social-icons:hover {
  border-radius: 50%;
}
.footer-copy-text-main {
  border-top: 1px solid #4c3352;
  padding: 23px 0px 33px 0px;
  margin-top: 45px;
}

.footer-copy-text-main .footer-copy-text {
  font-size: 21px;
  line-height: 24px;
  color: var(--main-white);
  font-family: "SulphurPoint-Regular";
}

.sidebar-social-main {
  padding-left: 15px;
  position: absolute;
  bottom: 10px;
  display: none;
}
.sidebar-social-main .sidebar-social-ul {
  display: flex;
  gap: 15px;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}
.sidebar-social-main .sidebar-social-li {
  max-width: max-content;
  padding: 0px;
  margin: 0px;
}

.sidebar-social-main .sidebar-social-link {
  text-decoration: none;
}

.sidebar-social-icons {
  height: 40px;
  width: 40px;
  object-fit: contain;
  transition: 0.5s all;
}
.sidebar-social-icons:hover {
  border-radius: 50%;
}