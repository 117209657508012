/* .follow-section {
      background-image: url(../../../../../assets/images/svg/follow-bg.svg);
      height: 348px;
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
} */

.follow-social-main {
  margin-top: 42px;
}

.follow-social-main .follow-social-ul {
  display: flex;
  gap: 30px;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}

.follow-social-main .follow-social-li {
  max-width: max-content;
  padding: 0px;
  margin: 0px;
}

.follow-social-main .follow-social-link {
  text-decoration: none;
}

.follow-social-main .follow-social-icons {
  height: 76px;
  width: 76px;
  object-fit: contain;
  transition: 0.5s all;
}

.follow-containt-main {
  max-width: 467px;
  width: 100%;
  margin-top: 70px;
}

.follow-containt-main .follow-text {
  font-size: 70px;
  font-family: "Super-Senior";
  color: var(--main-white);
  line-height: 80px;
}

.follow-left-img-main {
  position: relative;
}

.follow-icon-logo {
  /* right: -15px; */
  /* bottom: -32px; */
  /* width: 100%; */
  max-width: 242px;
  height: 254px;
  /* max-width: 242px; */
  object-fit: contain;
  /* transform: rotate(21deg); */
  /* animation: rotateCoin 2s linear infinite; */
}

.follow-logo-bottm {
  position: absolute;
  bottom: -15px;
  /* transform: translateX(-200px) rotate(360deg); */
}
.in-view{
    animation: moveclouds 2s linear both;
    -webkit-animation: moveclouds 2s linear both;
}
@keyframes moveclouds{
  0% {
    left: 0;
    transform: translateX(0) rotate(360deg);
    -webkit-transform: translateX(0) rotate(360deg);
    -moz-transform: translateX(0) rotate(360deg);
    -ms-transform: translateX(0) rotate(360deg);
    -o-transform: translateX(0) rotate(360deg);
}

100% {
    left: 100%;
    transform: translateX(-100%)  rotate(19deg);
    -webkit-transform: translateX(-100%)  rotate(19deg);
    -moz-transform: translateX(-100%)  rotate(19deg);
    -ms-transform: translateX(-100%)  rotate(19deg);
    -o-transform: translateX(-100%)  rotate(19deg);
}
}

.follow-flex {
  display: flex;
  justify-content: space-between;
}

.follow-left-img-main {
  position: relative;
}

.follow-section {
  max-width: 1920px;
  margin: auto;
  padding-top: 280px;
}

.follow-left-img {
  /* max-width: 857px;
  height: 348px; */
  width: 100%;
  object-fit: contain;
}

.follow-lamp-details{
  max-width: 857px;
}

.follow-right-img-main{
  max-width: 285px;
}

.follow-right-img-main .follow-right-img {
  object-fit: contain;
  height: 292px;
}

.follow-social-main .follow-social-icons:hover {
  border-radius: 50%;
}
@keyframes rotateCoin {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}
}

.lamp-one {
  max-width: max-content;
  position: absolute;
  bottom: 0;
  left: 9px;
}

.lamp-two{
      max-width: max-content;
      position: absolute;
  bottom: 0;
  left: 195px;
}

.lamp-three{
      max-width: max-content;
      position: absolute;
      bottom: 0;
      left: 9px;
}

.lamp-four{
      max-width: max-content;
      position: absolute;
  bottom: 0;
  left: 195px;
}

.lamp-two svg,.lamp-four svg {
      height: 101px;
  }

.lamp-one .lamp-on-off, .lamp-two .lamp-on-off, .lamp-three .lamp-on-off, .lamp-four .lamp-on-off{
  animation: lampColor 3s ease-in-out infinite;
  -webkit-animation: lampColor 3s ease-in-out infinite;
}

@keyframes lampColor {
  0% {
    fill: #00f08f; /* Initial color */
  }
  50% {
    fill: #240736; /* Intermediate color */
  }
  100% {
    fill: #00f08f; /* Final color */
  }
}

.follow-lamp-details, .follow-right-img-main {
  position: relative;
}

.follow-logo-bottom {
  position: absolute;
  right: -6px;
  bottom: -28px;
  cursor: pointer;
}

.rotated {
  transform: translateX(100px);
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  -o-transform: translateX(100px);
}