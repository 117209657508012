@media screen and (min-width: 1600px) and (max-width: 1919px) {
}

@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .tokenomics-img {
    width: 100%;
    max-width: 522px;
    height: 465px;
  }
  .tokenomics-box .tokenomics-box-text {
    font-size: 19px;
    line-height: 30px;
  }
  .tokenomics-box .tokenomics-sulphurpoint-text {
    font-size: 22px;
    line-height: 27px;
  }
  .tokenomics-box .tokenomics-super-senior-text {
    font-size: 21px;
    margin-bottom: 7px;
    line-height: 27px;
  }
  .token-bone-animation svg {
    width: 100%;
  }
  .token-bone-animation {
    top: -16px;
  }
  .token-address-contait-main .tokenomics-token-text {
    font-size: 36px;
    line-height: 35px;
  }
  .token-copy-box {
    font-size: 22px;
    line-height: 30px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1366px) {
  .tokenomics-img {
    max-width: 500px;
    height: 455px;
  }
  .smoke-box {
    right: 44%;
  }
  .token-bone-animation svg {
    width: 100%;
  }
  .token-copy-box {
    font-size: 21px;
    line-height: 26px;
  }
  .token-copy-flex {
    padding: 0px 40px;
  }
  /* .copied-tooltip {
    right: 61px;
  } */
  .container.roadmap-container {
    max-width: 1320px;
  }

  .roadmap-bg-section::after {
    width: 130px;
    background-position: left;
  }
  .follow-section {
    padding-top: 150px;
  }
}

/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .tokenomics-section-title .page-title {
    margin-bottom: 90px;
  }
  /* .tokenomics-row {
    display: flex !important;
    flex-direction: column-reverse;
  } */
  .tokenomics-img-main {
    margin-left: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .tokenomics-row {
    position: relative;
  }
  .tokenomics-flex-box-main {
    max-width: 80%;
    margin: auto auto 80px auto;
  }
  .token-address-contait-main {
    width: 55%;
  }
  .house-tokenomics {
    position: absolute;
    right: 5px;
    top: 50%;
  }
  .tokenomics-img {
    max-width: 360px;
    height: 360px;
  }
  .token-bone-animation svg {
    width: 99%;
  }
  .token-copy-box {
    font-size: 20px;
    line-height: 30px;
    max-width: 360px;
  }
  /* .copied-tooltip {
    right: 41px;
  } */
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  /*  */
  .tokenomics-section-title .page-title {
    margin-bottom: 90px;
  }
  .token-wallet-input-main {
    padding: 50px 0px;
  }
  /* .tokenomics-row {
  display: flex !important;
  flex-direction: column-reverse;
} */
  .tokenomics-img-main {
    margin-left: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .tokenomics-row {
    position: relative;
  }
  .tokenomics-flex-box-main {
    max-width: 90%;
    margin: auto auto 80px auto;
  }
  .token-address-contait-main {
    width: 60%;
  }
  .house-tokenomics {
    position: absolute;
    right: 5px;
    top: 50%;
  }
  .tokenomics-img {
    max-width: 270px;
    height: 270px;
  }
  .token-bone-animation svg {
    width: 99%;
    height: 200px;
  }
  .copy-icon {
    height: 25px;
    width: 25px;
  }
  #chimney-smoke-box {
    height: 50px;
    width: 30px;
    left: 10px;
  }
  .token-copy-box {
    font-size: 17px;
    line-height: 30px;
    max-width: 260px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* .copied-tooltip {
    right: 41px;
  } */
  .token-address-contait-main .tokenomics-token-text {
    font-size: 25px;
    line-height: 35px;
  }
}

@media screen and (max-width: 767px) {
  .copied-tooltip {
    top: -23px;
    font-size: 11px !important;
    bottom: unset;
  }
  .tokenomics-section-title .page-title {
    margin-bottom: 50px;
  }
  .tokenomics-row {
    display: flex !important;
    flex-direction: column-reverse;
  }

  .tokenomics-img-main {
    margin: 0px 30px 0px 30px;
    text-align: center;
  }

  .tokenomics-img {
    max-width: 532px;
    height: 292px;
    margin-bottom: 30px;
  }
  .tokenomics-img-main {
    margin: 0px 30px 0px 30px;
  }
  .tokenomics-flex-box-main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .token-bone-animation svg {
    width: 100%;
  }
  .token-copy-flex {
    gap: 10px;
    padding: 0px 30px;
}
.token-copy-box {
  font-size: 15px;
  line-height: 30px;
  max-width: unset;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.copy-icon {
  height: 25px;
  width: 25px;
}
.token-address-contait-main .tokenomics-token-text {
  font-size: 20px;
  line-height: 36px;
}
.token-bone-animation {
  top: -59px;
}
.token-wallet-input-main {
  padding: 29px 0px;
}
.tokenomics-section{
  padding-top: 100px;
}
}
